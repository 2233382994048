import http from '@/utils/http'
import type { PaginationResData } from '@/types'
import type {
  PermissionReturn,
  permissionEnter,
  PermissionTableReturn,
  PermissionTableEnter,
  PermissionDetailsReturn,
  ResourceDetailsReturn,
  fieldDetailsReturn,
  menuTree,
  TreeReturn,
  TreeEnter,
  TreeSequenceEnter,
  updateBasicEnter,
  updateFieldAll,
  updateResourceAll,
  Child,
  Datum,
  checkQueryMenuRes,
} from './menu.d'
import type { PermissionResource } from '../resource-list/resource-list'

export const createPermission = (data: permissionEnter) => {
  return http<PermissionReturn>({ url: '/ram/api/permissions/create-permission', data })
}
export const queryTable = (data: PermissionTableEnter) => {
  return http<PermissionTableReturn>({
    url: '/ram/api/permissions/list-permission-by-page',
    data,
  })
}
export const PermissionDetails = (data: { id: number }) => {
  return http<PermissionDetailsReturn>({
    url: '/ram/api/permissions/query-permission',
    data,
  })
}
export const resourceDetails = (data: { permissionId: number }) => {
  return http<ResourceDetailsReturn>({
    url: '/ram/api/permissions/list-resource-by-permission-id',
    data,
  })
}
export const fieldDetails = (data: { permissionId: number }) => {
  return http<fieldDetailsReturn>({
    url: '/ram/api/permissions/list-permission-field',
    data,
  })
}
export const platformTree = (data: { menuName: string }) => {
  return http<menuTree>({
    url: '/ram/api/platform-menu/list-menu-tree',
    data,
  })
}
export const organizationTree = (data: { menuName: string }) => {
  return http<menuTree>({
    url: '/ram/api/organization-menu/list-menu-tree',
    data,
  })
}
export const createPlatformTree = (data: TreeEnter) => {
  return http<TreeReturn>({
    url: '/ram/api/platform-menu/create-menu',
    data,
  })
}
export const createOrganizationTree = (data: TreeEnter) => {
  return http<TreeReturn>({
    url: '/ram/api/organization-menu/create-menu',
    data,
  })
}
export const updateOrganizationTree = (data: TreeEnter) => {
  return http<TreeReturn>({
    url: '/ram/api/organization-menu/update-menu',
    data,
  })
}
export const updatePlatformTree = (data: TreeEnter) => {
  return http<TreeReturn>({
    url: '/ram/api/platform-menu/update-menu',
    data,
  })
}
export const deleteOrganizationTree = (data: { id: number }) => {
  return http<TreeReturn>({
    url: '/ram/api/organization-menu/delete-menu',
    data,
  })
}
export const deletePlatformTree = (data: { id: number }) => {
  return http<TreeReturn>({
    url: '/ram/api/platform-menu/delete-menu',
    data,
  })
}
export const updateSequence = (data: TreeSequenceEnter) => {
  return http({
    url: '/ram/api/organization-menu/update-menu-sequence',
    data,
  })
}
export const updateBasic = (data: updateBasicEnter) => {
  return http<PermissionDetailsReturn>({ url: '/ram/api/permissions/update-permission', data })
}
export const queryResource = (data: { permissionId: number }) => {
  return http<updateResourceAll[]>({
    url: '/ram/api/permissions/list-resource-by-permission-id',
    data,
  })
}
export const createResource = (data: updateResourceAll[]) => {
  return http<updateResourceAll[]>({
    url: '/ram/api/permissions/create-permission-resource',
    data,
  })
}
export const updateField = (data: updateFieldAll) => {
  return http<updateFieldAll[]>({
    url: '/ram/api/permissions/update-permission-field',
    data,
  })
}
export const queryField = (data: { permissionId: number }) => {
  return http<updateFieldAll[]>({
    url: '/ram/api/permissions/list-permission-field',
    data,
  })
}
export const createField = (data: updateFieldAll[]) => {
  return http<updateFieldAll[]>({
    url: '/ram/api/permissions/create-permission-field',
    data,
  })
}
export const deleteOne = (data: { id: number }) => {
  return http({
    url: '/ram/api/permissions/delete-permission',
    data,
  })
}

export const queryMenuByName = (data: { name: string }) => {
  return http<Datum[]>({
    url: '/ram/api/organization-menu/fuzzy-query-menu-by-name',
    data,
  })
}

export const checkQueryMenu = (data: {
  /**
   * 业务主键
   */
  id?: number
}) => {
  return http<checkQueryMenuRes>({
    url: '/ram/api/organization-menu/query-menu',
    data,
  })
}

export const batchCreatePermissionResource = (data: {
  /**
   * 权限ID
   */
  permissionId: number
  /**
   * 资源ID集合
   */
  resourceList: number[]
}) => {
  return http({
    url: '/ram/api/resources/batch-create-permission-resource',
    data,
  })
}

export const deletePermissionAddResource = (data: {
  /**
   * 权限ID
   */
  permissionId: number
  /**
   * 资源ID
   */
  resourceId: number
}) => {
  return http({
    url: '/ram/api/resources/delete-permission-add-resource',
    data,
  })
}

export const queryResourceListByPage = (data: {
  /**
   * 模块ID
   */
  moduleId?: number
  /**
   * 模块ID集合,非必传
   */
  moduleIdList?: number[]
  /**
   * 排序字段
   */
  orderByColumn?: string
  /**
   * 页码
   */
  pageIndex?: number
  /**
   * 页数
   */
  pageSize?: number
  /**
   * 权限ID
   */
  permissionId: number
  /**
   * 资源是否私有,默认为true,非必传
   */
  privated?: boolean
  /**
   * 资源名称
   */
  resourceName?: string
  /**
   * 使用范围
   */
  resourceScope: string
  /**
   * 资源url
   */
  resourceUrl?: string
  /**
   * 排序方式
   */
  sort?: string
}) => {
  return http<PaginationResData<PermissionResource[]>>({
    url: '/ram/api/resources/query-resource-list-by-page',
    data,
  })
}
