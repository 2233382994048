import http from '../utils/http'
import type { MenuAsideNavData } from './types/menuAsideType'

export const apiMenuAsideData = (baseUrl: string, selectedUrl?: string) => {
  const url = selectedUrl
    ? `/acl/menu/describe-menu-info?url=${selectedUrl}`
    : '/acl/menu/describe-menu-info'

  return http({
    method: 'get',
    baseURL: baseUrl,
    withCredentials: true,
    url,
  }).then((res: any) => {
    if (res.data.code === 0) {
      return res.data
    }
    return null
  })
}

export const apiMenuInfo = async (baseUrl: string) => {
  const res: any = await http({
    method: 'get',
    baseURL: baseUrl,
    withCredentials: true,
    url: '/acl/menu/list-menu-count-info',
  })
  if (res.data.code === 0) {
    return res.data
  }
  return null
}

export const apiListMenu = () => {
  return http<MenuAsideNavData[]>({
    url: '/ram/api/employee/list-menu',
    loading: false,
  })
}

export default {}
