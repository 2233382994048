import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import type { ResponseData } from '@/pages/entrance/entrance'

export const useUser = defineStore('user', () => {
  let defaultUser = []
  if (localStorage.getItem('userInfo')) {
    defaultUser = JSON.parse(localStorage.getItem('userInfo') || '{}')
  }

  const user = ref<ResponseData>(defaultUser)
  const isLogin = computed(() => !!user.value.userId)
  function setUser(data: ResponseData) {
    user.value = data
    localStorage.setItem('userInfo', JSON.stringify(data))
  }

  function deleteUser() {
    setUser({} as ResponseData)
  }

  return { user, isLogin, setUser, deleteUser }
})
