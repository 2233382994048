import type { Pagination } from '@/types'
import type { IKeywords } from '@skycore/skycore-ui/lib/form/types'
import { omit, pick } from 'lodash-es'

export const copy = (str: string) => {
  const input = document.createElement('textarea')
  document.body.appendChild(input)
  input.value = str
  input.select()
  document.execCommand('Copy')
  document.body.removeChild(input)
}

export const getQueryParams = (keywords: IKeywords, pagination?: Pagination) => {
  const queryParams = {
    ...omit(keywords, 'createdTime', 'updatedTime', '_searchOptions'),
    ...pick(pagination || {}, 'pageIndex', 'pageSize'),
  } as IKeywords & {
    createdStartTime: string | undefined
    createdEndTime: string | undefined
    updatedStartTime: string | undefined
    updatedEndTime: string | undefined
    pageIndex: number
    pageSize: number | undefined
  }
  return queryParams
}

export const getDomain = () => {
  const { hostname } = window.location
  const OfficeDomainMap: Record<string, string> = {
    localhost: 'localhost:5000',
    'saas.dev.skycoresaas.com': 'dev.skycoresaas.com',
    'saas.qa.skycoresaas.com': 'qa.skycoresaas.com',
    'saas.uat.skycoresaas.com': 'uat.skycoresaas.com',
    'saas.www.skycoresaas.com': 'www.skycoresaas.com',
  }

  return `//${OfficeDomainMap[hostname]}`
}

export const addressRegExp = /^[\u4e00-\u9fa5a-zA-Z0-9-_*！@#￥%……&*()（）~。，。？、【】]+$/

export const addressRule = {
  pattern: addressRegExp,
  message: '请输入中英文、数字或标点符号',
}
