<template>
  <ScUserPrefer :load-fn="loadFn" :sync-fn="syncFn">
    <a-config-provider :locale="zhCN">
      <router-view></router-view>
    </a-config-provider>
  </ScUserPrefer>
</template>
<script setup lang="ts">
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { watch } from 'vue'
import { ScUserPrefer, useDrawerModeDefaultPrefer } from '@skycore/skycore-ui'
import { syncUserPrefer, queryUserPrefer } from './app.api'
import { useUser } from './stores/user'

const userStore = useUser()

async function loadFn(key: string) {
  if (userStore.isLogin) {
    const data = await queryUserPrefer(key)
    return data.preferenceJson
  }
  return ''
}

function syncFn(key: string, value: string) {
  if (userStore.isLogin) {
    syncUserPrefer(key, value)
  }
}

watch(
  () => userStore.isLogin,
  l => {
    if (l) {
      loadFn('sc-searchform-tab:default').then(res => {
        if (res) {
          localStorage.setItem('sc-searchform-tab:default', res)
        }
      })
      loadFn('sc-drawer:default').then(res => {
        localStorage.setItem('sc-searchform-tab:default', res ?? 'drawer')
      })
    }
  },
)
</script>
