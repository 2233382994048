import { message, Spin } from 'ant-design-vue'
import axios, { type AxiosResponse } from 'axios'
import type { AxiosRequestConfig } from 'axios'
import { createApp, nextTick } from 'vue'
import i18n from '@/i18n'
import { getQuery } from './url'

type HttpConfig = AxiosRequestConfig & { proxyEnv?: string; loading?: boolean }

const {
  global: { t },
} = i18n

message.config({ maxCount: 1 })

let requestCount = 0
const prevent = (e: Event) => e.stopImmediatePropagation()
const loading = () => {
  if (requestCount === 0) {
    nextTick().then(() => {
      const dom = document.createElement('div')
      dom.setAttribute('id', 'loading')
      dom.addEventListener('mousedown', prevent)
      dom.addEventListener('touchstart', prevent)
      document.body.appendChild(dom)
      createApp(Spin).mount(dom)
    })
  }
  requestCount += 1
}
const hideLoading = () => {
  requestCount -= 1
  const loadingDom = document.getElementById('loading')
  if (requestCount === 0 && loadingDom) {
    document.body.removeChild(loadingDom)
  }
}

const isDev = import.meta.env.DEV
const env = getQuery('proxy') || import.meta.env.VITE_API_ENV
// 根据 env 参数切换接口环境，仅在开发环境生效；proxy 保留参数，不要在业务接口中使用
const envSet = new Set(['mock', 'dev', 'qa', 'uat', 'pro'])
const envUrlDic: { [key: string]: string } = {
  mock: '/mock',
  dev: '/dev',
  qa: '/qa',
  uat: '/uat',
  prod: '/prod',
}

export const getBaseUrl = (config: HttpConfig = {}): string => {
  let baseURL = ''
  if (isDev) {
    const envInner = env || config.proxyEnv || 'mock'
    if (envInner && envSet.has(envInner)) {
      // TODO 地址填充
      baseURL = envUrlDic[envInner]
    }
  } else if (process.env.NODE_ENV === 'production' && process.env.CLIENT === 'electron') {
    let baseURLInner = ''
    if ((config.url || '').startsWith('/ram')) baseURLInner = '/1713234-0-default'
    if ((config.url || '').startsWith('/pdm')) baseURLInner = '/1783640-0-default'
    if ((config.url || '').startsWith('/common')) baseURLInner = '/1963470-0-default'
    if ((config.url || '').startsWith('/oms')) baseURLInner = '/2076887-0-default'
    if ((config.url || '').startsWith('/crm')) baseURLInner = '/2186502-0-default'
    if ((config.url || '').startsWith('/pms')) baseURLInner = '/2373963-0-default'
    baseURL = import.meta.env.VITE_API_PREFIX + baseURLInner
  }
  return baseURL
}

const triggerDownload = (url: string, filename = '') => {
  const link = document.createElement('a')
  link.style.display = 'none'
  // a 标签的 download 属性就是下载下来的文件名
  link.download = filename as string
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const preview = (uuid: string) => {
  return `${getBaseUrl()}/common/api/file/preview-file/${uuid}`
}
export const previewFile = (uuid: string) => {
  return `${getBaseUrl()}/common/api/file/kk-file-view/${uuid}`
}
export const download = (uuid: string) => {
  triggerDownload(`${getBaseUrl()}/common/api/file/download-file/${uuid}`)
}

export const commonHeader = () => ({
  'x-requested-with': 'XMLHttpRequest',
  Authorization: localStorage.getItem('Authorization') || false,
})

/**
 * 请求
 * @param isDownload 只有响应类型是Blob时使用，true: 下载 false: 预览 undefined(不传): 按常规接口处理
 */
const http: <T>(config: HttpConfig, isDownload?: boolean) => Promise<T> = (config, isDownload) => {
  return new Promise((resolve, reject) => {
    config.validateStatus = status => {
      return status < 500
    }
    config.headers = {
      ...commonHeader(),
      ...config.headers,
    }

    config.baseURL = getBaseUrl(config)

    if (config.proxyEnv) {
      delete config.proxyEnv
    }
    if (!config.method) config.method = 'post'
    if (isDownload !== undefined) {
      config.responseType = 'blob'
      config.loading = false
    }
    if (config.loading !== false) {
      loading()
    }
    axios(config)
      .then(async (response: AxiosResponse) => {
        const { headers } = response
        const res = response.data
        if (response.status === 200) {
          // mock 不校验 code
          if (env === 'mock') {
            resolve(res.data)
            return
          }

          if (isDownload !== undefined && res instanceof Blob) {
            if (headers['content-type']?.includes('json')) {
              response.data.text().then((data: string) => {
                try {
                  message.error(JSON.parse(data).message)
                } catch {
                  reject()
                }
              })
              return
            }
            const mimeType = headers['content-type'] ?? 'application/pdf'
            const objectURL = window.URL.createObjectURL(new Blob([res], { type: mimeType }))
            if (isDownload) {
              const filename = decodeURIComponent(
                headers['content-disposition']?.split(';')[1].split('=')[1] as string,
              )
              triggerDownload(objectURL, filename)
            } else {
              window.open(objectURL)
            }
            // 释放的 URL 对象以及移除 a 标签
            URL.revokeObjectURL(objectURL)
            resolve(res as any)
            return
          }

          if (typeof res === 'object') {
            if (res.code === 200) {
              resolve(res.data)
              return
            }

            // 未登录或登录信息失效
            if (res.code === 10203001 || res.code === 10203003) {
              let params = ''
              if (res?.data?.isRedirectToOriginalPage) {
                params = `?redirectUrl=${encodeURIComponent(window.location.href)}`
              }
              window.location.replace(`/#/login${params}`)
              window.location.reload()
              return
            }
          }
        }
        if (headers.redirect_url) {
          window.location.href = headers.redirect_url
        }
        const errorRes = {
          message: t('message.commonRequestFailed'),
          ...(res || {}),
        }
        message.error(errorRes.message)
        reject(response)
      })
      .catch(response => {
        message.error(response?.data?.message || t('message.commonRequestFailed'))
      })
      .finally(() => {
        if (config.loading !== false) {
          hideLoading()
        }
      })
  })
}

export const postExport = (url: string, data: any, isDownload = true) => {
  return http(
    {
      url,
      data,
    },
    isDownload,
  )
}

export default http
