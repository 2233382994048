<template>
  <SCLayout v-if="menuListState.length" :asideMenus="menuListState">
    <router-view v-slot="{ Component, route }">
      <keep-alive :include="include">
        <component v-if="!route.meta.link" :is="Component" :key="route.path" />
      </keep-alive>
    </router-view>
  </SCLayout>
  <SkeletonPage v-else />
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { queryLoginInfo } from '@/pages/entrance/entrance.api'
import { useOrganization } from '@/stores/organization'
import SCLayout from './layout-index.vue'
import SkeletonPage from '../components/SkeletonPage.vue'
import { useMenuData, useNavTab } from './store'
import { apiListMenu } from './apiMenuAside'
import type { MenuAsideNavData } from './types/menuAsideType'

const menuDataStore = useMenuData()
const { setData } = menuDataStore
const menuListState = ref<MenuAsideNavData[]>([])
const include = ref(['manual-order'])
const { setCurrentOrganizationId } = useOrganization()

const cachePage: { [key: string]: string } = {
  '/sale/manual-order': 'manual-order',
  '/material/material/private': 'material-private',
  '/public/material-public/material-public': 'material-public',
  '/material/material/category-attribute': 'category-attribute',
  '/public/material-public/category-attribute': 'public-category-attribute',
  '/material/brand/structure': 'material-structure',
}

onMounted(() => {
  const tabStore = useNavTab()

  const { tabs } = storeToRefs(tabStore)

  watch(
    () => tabs.value,
    v => {
      const activePage: string[] = []
      v.data.forEach(({ path }) => {
        const i = cachePage[path]
        if (i) {
          activePage.push(i)
        }
      })
      include.value = activePage.filter(a => a)
    },
    {
      deep: true,
    },
  )
})
// 查询登录信息接口
queryLoginInfo().then(res => {
  const { organizationId, organizationType, token } = res
  if (!localStorage.getItem('organizationId') && organizationId) {
    setCurrentOrganizationId(organizationId, organizationType)
  }
  if (token) {
    localStorage.removeItem('Authorization')
    localStorage.setItem('Authorization', token as string)
  }
  apiListMenu().then(re => {
    setData(re)
    menuListState.value = re
  })
})
</script>
