import { createRouter, createWebHashHistory } from 'vue-router'
import LayoutComponent from '@/layout/layout-component.vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: '/home/index',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/phoneLogin',
      name: 'phoneLogin',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/chatLogin',
      name: 'chatLogin',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/organization',
      name: 'organization',
      component: () => import('../pages/entrance/organization-index.vue'),
      children: [
        {
          path: '/organization/join',
          name: 'organization-join',
          component: () => import('../pages/entrance/organization-index.vue'),
        },
        {
          path: '/organization/create',
          name: 'organization-create',
          component: () => import('../pages/entrance/organization-index.vue'),
        },
      ],
    },
    {
      path: '/edit-contract-template',
      name: 'editContractTemplate',
      component: () =>
        import('../pages/settings/contract-template-editor/contract-template-editor.vue'),
    },
    {
      path: '/preview-contract-template',
      name: 'previewContractTemplate',
      component: () =>
        import('../pages/settings/contract-template-editor/contract-template-editor.vue'),
    },
    {
      path: '/home',
      name: 'home',
      component: LayoutComponent,
      children: [
        {
          path: '/home/index',
          name: 'homeIndex',
          component: () => import('../pages/home/home-index.vue'),
        },
      ],
    },
    {
      path: '/invite',
      name: 'invite',
      component: () => import('../pages/entrance/invite-employee/invite-employee-index.vue'),
    },
    {
      path: '/project',
      component: LayoutComponent,
      redirect: '/project/management/list',
      children: [
        {
          path: '/project/management/list',
          meta: {
            title: '项目列表',
          },
          component: () => import('../pages/project/management/list/project-list-index.vue'),
        },
      ],
    },
    {
      path: '/platform',
      component: LayoutComponent,
      redirect: '/platform/organization/organization',
      children: [
        {
          path: '/platform/resources/menu',
          meta: {
            title: '平台菜单',
          },
          component: () => import('../pages/platform/resources/menu/menu-index.vue'),
        },
        {
          path: '/platform/resources/organization',

          meta: {
            title: '组织菜单',
          },
          component: () =>
            import('../pages/platform/resources/organization/organization-index.vue'),
        },
        {
          path: '/platform/resources/product-package',
          meta: {
            title: '产品包管理',
          },
          component: () =>
            import('../pages/platform/resources/product-package/product-package-index.vue'),
        },
        {
          path: '/platform/resources/resource-list',
          meta: {
            title: '资源列表',
          },
          component: () =>
            import('../pages/platform/resources/resource-list/resource-list-index.vue'),
        },
        {
          path: '/platform/resources/default-role',
          meta: {
            title: '默认角色',
          },
          component: () =>
            import('../pages/platform/resources/default-role/default-role-index.vue'),
        },
        {
          path: '/platform/user/staff',
          meta: {
            title: '员工管理',
          },
          component: () => import('../pages/platform/user/staff/staff-index.vue'),
        },
        {
          path: '/platform/user/role',
          meta: {
            title: '角色管理',
          },
          component: () => import('../pages/platform/user/role/role-index.vue'),
        },
        {
          path: '/platform/organization/organization',
          meta: {
            title: '组织管理',
          },
          component: () =>
            import('../pages/platform/organization/organization/organization-index.vue'),
        },
        {
          path: '/platform/organization/user',
          meta: {
            title: '用户管理',
          },
          component: () => import('../pages/platform/organization/user/user-index.vue'),
        },
        {
          path: '/platform/operations/material-manual-matching',
          meta: {
            title: '物料手动匹配',
          },
          component: () =>
            import(
              '../pages/platform/operations/material-manual-matching/material-manual-matching-index.vue'
            ),
        },
        {
          path: '/platform/operations/coding-rule',
          meta: {
            title: '编码规则',
          },
          component: () => import('../pages/platform/operations/coding-rule/coding-rule-index.vue'),
        },
      ],
    },
    {
      path: '/settings',
      component: LayoutComponent,
      redirect: '/settings/staff',
      children: [
        {
          path: '/settings/staff',
          meta: {
            title: '员工管理',
          },
          component: () => import('../pages/settings/staff/staff-index.vue'),
        },
        {
          path: '/settings/organization/self-settings',
          meta: {
            title: '个人设置',
          },
          component: () =>
            import('../pages/settings/organization/self-settings/self-settings-index.vue'),
        },
        {
          path: '/settings/organization/settlement-type',
          meta: {
            title: '结算方式',
          },
          component: () =>
            import('../pages/settings/organization/settlement-type/settlement-type-index.vue'),
        },
        {
          path: '/settings/organization/organization',
          meta: {
            title: '组织管理',
          },
          component: () =>
            import('../pages/settings/organization/organization/organization-index.vue'),
        },
        {
          path: '/settings/organization/role',
          meta: {
            title: '角色管理',
          },
          component: () => import('../pages/settings/organization/role/role-index.vue'),
        },
        {
          path: '/settings/organization/organization-menu',
          meta: {
            title: '组织菜单',
          },
          component: () =>
            import('../pages/settings/organization/organization-menu/organization-menu-index.vue'),
        },
        {
          path: '/settings/workflow/set',
          meta: {
            title: '流程设置',
          },
          component: () => import('@/pages/settings/workflow/flow-model-index.vue'),
        },
        {
          path: '/settings/contract-template',
          meta: {
            title: '合同模板',
          },
          component: () =>
            import('../pages/settings/contract-template/contract-template-index.vue'),
        },
        {
          path: '/settings/sales-setup',
          meta: {
            title: '销售设置',
          },
          component: () => import('../pages/settings/sales-setup/sales-setup-index.vue'),
        },
        {
          path: '/settings/mall-set',
          meta: {
            title: '商城设置',
          },
          component: () => import('../pages/sale/transaction/mall-set/mall-set-index.vue'),
        },
        {
          path: '/settings/home-page-product',
          meta: {
            title: '首页商品设置',
          },
          component: () =>
            import('../pages/sale/transaction/home-page-product/home-page-product.vue'),
        },
        {
          path: '/settings/workflow/set',
          meta: {
            title: '流程设置',
          },
          component: () => import('@/pages/settings/workflow/flow-model-index.vue'),
        },
        {
          path: '/settings/my-coupon',
          meta: {
            title: '我的优惠券',
          },
          component: () => import('@/pages/settings/my-coupon/my-coupon-index.vue'),
        },
        {
          path: '/settings/prefer-setting',
          meta: {
            title: '偏好设置',
          },
          component: () => import('@/pages/settings/prefer-setting/prefer-setting-index.vue'),
        },
        {
          path: '/settings/seat-settings/EB',
          meta: {
            title: 'EB席位管理',
          },
          component: () => import('@/pages/settings/seat/eb/eb-seat-index.vue'),
        },

        {
          path: '/settings/seat-settings/DFM',
          meta: {
            title: 'DFM席位管理',
          },
          component: () => import('@/pages/settings/seat/dfm/dfm-seat-index.vue'),
        },
        {
          path: '/settings/seat-settings/TG',
          meta: {
            title: 'TG席位管理',
          },
          component: () => import('@/pages/settings/seat/tg/tg-seat-index.vue'),
        },
      ],
    },
    {
      path: '/material',
      component: LayoutComponent,
      redirect: '/material/material/category-attribute',
      children: [
        {
          path: '/material/material/category-attribute',
          meta: { title: '品类属性' },
          component: () =>
            import('../pages/material/material/category-attribute/category-index.vue'),
        },
        {
          path: '/material/material/private/drawer/detail',
          meta: { title: '物料列表详情' },
          props: route => ({ ...route.query }),
          name: 'private-drawer',
          component: () => import('../pages/material/material/private/private-drawer.vue'),
        },
        {
          path: '/material/material/private',
          meta: { title: '物料列表' },
          component: () => import('../pages/material/material/private/private-index.vue'),
        },
        {
          path: '/material/material/public',
          meta: { title: '物料公库' },
          component: () => import('../pages/material/material/public/material-public-index.vue'),
        },
        {
          path: '/material/material/device-model',
          meta: { title: '器件模型' },
          component: () => import('../pages/material/material/device-model/device-model-index.vue'),
        },
        {
          path: '/material/material/raw-material-setting',
          meta: { title: '原料设置' },
          component: () =>
            import(
              '../pages/material/material/raw-material-setting/raw-material-setting-index.vue'
            ),
        },
        {
          path: '/material/document/type',
          meta: {
            title: '文档类型',
          },
          component: () => import('../pages/material/document/type/type-index.vue'),
        },
        {
          path: '/material/document/list',
          meta: {
            title: '文档列表',
          },
          component: () => import('../pages/material/document/list/list-index.vue'),
        },
        {
          path: '/material/document/group',
          meta: {
            title: '文档分组',
          },
          component: () => import('../pages/material/document/group/group-index.vue'),
        },
        {
          path: '/material/process/process-list',
          meta: {
            title: '工序管理',
          },
          component: () => import('../pages/material/process/process-list/process-list-index.vue'),
        },
        {
          path: '/material/process/route-list',
          meta: {
            title: '工艺路线',
          },
          component: () => import('../pages/material/process/route-list/route-list-index.vue'),
        },
        {
          path: '/material/process/equipment-type',
          meta: {
            title: '设备类型',
          },
          component: () =>
            import('../pages/material/process/equipment-type/equipment-type-index.vue'),
        },
        {
          path: '/material/process/work-type',
          meta: {
            title: '工种管理',
          },
          component: () => import('../pages/material/process/work-type/work-type-index.vue'),
        },
        {
          path: '/material/brand/list',
          meta: {
            title: '品牌管理',
          },
          component: () => import('../pages/material/brand/list/brand-index.vue'),
        },
        {
          path: '/material/brand/series',
          meta: {
            title: '产品系列',
          },
          component: () => import('../pages/material/brand/series/series-index.vue'),
        },

        {
          path: '/material/brand/structure',
          meta: {
            title: '产品结构',
          },
          component: () => import('../pages/material/brand/structure/structure-index.vue'),
        },
        {
          path: '/material/bom/bom-list',
          meta: {
            title: 'BOM列表',
          },
          component: () => import('../pages/material/bom/bom-list/bom-list-index.vue'),
        },
      ],
    },
    {
      path: '/public',
      component: LayoutComponent,
      redirect: '/public/material-public/category-attribute',
      children: [
        {
          path: '/public/material-public/category-attribute',
          meta: { title: '品类属性' },
          component: () =>
            import('../pages/public/material-public/category-attribute/category-index.vue'),
        },
        {
          path: '/public/material-public/material-public',
          meta: { title: '物料公库' },
          component: () =>
            import('../pages/public/material-public/material-public/material-public-index.vue'),
        },

        {
          path: '/public/material-public/public-list',
          meta: { title: '公库列表' },
          component: () =>
            import('../pages/public/material-public/public-list/public-list-index.vue'),
        },
        {
          path: '/public/material-public/device-model',
          meta: { title: '器件模型' },
          component: () =>
            import('../pages/public/material-public/device-model/device-model-index.vue'),
        },
        {
          path: '/public/material-public/series',
          meta: { title: '系列管理' },
          component: () => import('../pages/public/material-public/series/series-index.vue'),
        },
        {
          path: '/public/material-public/brand',
          meta: { title: '品牌管理' },
          component: () => import('../pages/public/material-public/brand/brand-index.vue'),
        },
        {
          path: '/public/material-public/document',
          meta: { title: '文档管理' },
          component: () => import('../pages/public/material-public/document/document-index.vue'),
        },
      ],
    },
    {
      path: '/sale',
      component: LayoutComponent,
      redirect: '/sale/price/mpg',
      children: [
        {
          path: '/sale/price/authority-level',
          meta: { title: '权限层级' },
          component: () => import('../pages/sale/price/authority-level/authority-level-index.vue'),
        },
        {
          path: '/sale/price/mpg',
          meta: { title: 'MPG列表' },
          component: () => import('../pages/sale/price/mpg/mpg-index.vue'),
        },
        {
          path: '/sale/price/product',
          meta: { title: '产品管理' },
          component: () => import('../pages/sale/price/product/product-index.vue'),
        },
        {
          path: '/sale/price/saleable-category',
          meta: { title: '可售品类' },
          component: () =>
            import('../pages/sale/price/saleable-category/saleable-category-index.vue'),
        },
        {
          path: '/sale/price/series-setting',
          meta: { title: '机柜系列设置' },
          component: () => import('../pages/sale/price/series-setting/series-setting-index.vue'),
        },
        {
          path: '/sale/price/customer-discount',
          meta: { title: '客户折扣' },
          component: () =>
            import('../pages/sale/price/customer-discount/customer-discount-index.vue'),
        },
        {
          path: '/sale/price/promotion',
          meta: { title: '营销列表' },
          component: () => import('../pages/sale/price/promotion/promotion-index.vue'),
        },
        {
          path: '/sale/customer/my-customer',
          meta: { title: '我的客户' },
          component: () => import('../pages/customer/customer/my-customer/my-customer-index.vue'),
        },
        {
          path: '/sale/customer/customer-segmentation',
          meta: { title: '客户分群' },
          component: () =>
            import(
              '../pages/customer/customer/customer-segmentation/customer-segmentation-index.vue'
            ),
        },
        {
          path: '/sale/transaction/order-list-skycore',
          meta: { title: '订单列表（火山湖）' },
          component: () =>
            import('../pages/sale/transaction/order-list-skycore/order-list-skycore-index.vue'),
        },

        {
          path: '/purchase/purchase-info/shop-cart/order-settlement',
          meta: { title: '订单结算' },
          component: () =>
            import('../pages/sale/transaction/order-settlement/order-settlement-index.vue'),
        },
        {
          path: '/purchase/purchase-info/shop-cart/order-pay',
          meta: { title: '支付页面' },
          component: () => import('../pages/sale/transaction/order-pay/order-pay-index.vue'),
        },
        {
          path: '/purchase/purchase-info/shop-cart/payment-success',
          meta: { title: '订单支付' },
          component: () => import('../pages/sale/transaction/order-pay/payment-success-index.vue'),
        },
        {
          path: '/sale/transaction/warehouse-setup',
          meta: { title: '仓库设置' },
          component: () =>
            import('../pages/sale/transaction/warehouse-setup/warehouse-setup-index.vue'),
        },
        {
          path: '/sale/transaction/logistics-setup',
          meta: { title: '物流设置' },
          component: () =>
            import('../pages/sale/transaction/logistics-setup/logistics-setup-index.vue'),
        },
        {
          path: '/sale/sever-setup/serve',
          meta: { title: '服务设置' },
          component: () => import('../pages/sale/sever/serve-setup/serve-setup-index.vue'),
        },
        {
          path: '/sale/coupon/coupon',
          meta: { title: '优惠券' },
          component: () => import('../pages/sale/coupon/coupon/coupon-index.vue'),
        },
        {
          path: '/sale/coupon/coupon-record',
          meta: { title: '发放记录' },
          component: () => import('../pages/sale/coupon/send-record/send-record-index.vue'),
        },
        {
          path: '/sale/coupon/coupon-list',
          meta: { title: '优惠券列表' },
          component: () => import('../pages/sale/coupon/coupon-list/coupon-list-index.vue'),
        },
        // 商务手工单
        {
          path: '/sale/manual-order',
          meta: { title: '新建订单' },
          component: () => import('../pages/sale/manual-order/manual-order-index.vue'),
        },
        {
          path: '/sale/transaction/change-order',
          meta: { title: '订单变更单' },
          component: () => import('../pages/sale/transaction/change-order/change-order-index.vue'),
        },
      ],
    },
    {
      path: '/purchase',
      component: LayoutComponent,
      redirect: '/purchase/purchase-info/order-list-customer',
      children: [
        {
          path: '/purchase/transaction/order-list-customer',
          meta: { title: '机柜采购单' },
          component: () =>
            import(
              '../pages/purchase/purchase-info/order-list-customer/order-list-customer-index.vue'
            ),
        },
        {
          path: '/purchase/purchase-info/shop-cart',
          meta: { title: '愿望清单' },
          component: () => import('../pages/purchase/purchase-info/shop-cart/shop-cart-index.vue'),
        },
        {
          path: '/purchase/series-model-select',
          meta: { title: '机柜选型' },
          component: () => import('../pages/shopping/series/series-model-select-index.vue'),
        },
        {
          path: '/purchase/accessories-select',
          meta: { title: '配件选型' },
          component: () => import('../pages/shopping/series/accessory-model-select-index.vue'),
        },
        {
          path: '/purchase/accessories-detail',
          meta: { title: '配件详情' },
          component: () => import('../pages/shopping/series/accessory-detail-index.vue'),
        },
      ],
    },

    {
      path: '/workbench',
      component: LayoutComponent,
      redirect: '/workbench/home',
      children: [
        {
          path: '/workbench/home',
          meta: { title: '首页' },
          component: () => import('../pages/home/home-index.vue'),
        },
        {
          path: '/workbench/my-task/initiated',
          meta: { title: '我的发起' },
          component: () => import('../pages/workbench/my-task/my-launch/my-launch-index.vue'),
        },
        {
          path: '/workbench/my-task/to-do',
          meta: { title: '我的待办' },
          component: () => import('../pages/workbench/my-task/my-backlog/my-backlog-index.vue'),
        },
        {
          path: '/workbench/my-task/done',
          meta: { title: '我的已办' },
          component: () =>
            import('../pages/workbench/my-task/my-have-finished/my-have-finished-index.vue'),
        },
        {
          path: '/workbench/my-task/cc-me',
          meta: { title: '抄送我的' },
          component: () =>
            import('../pages/workbench/my-task/my-carbon-copy/my-carbon-copy-index.vue'),
        },
      ],
    },

    {
      path: '/finance',
      component: LayoutComponent,
      redirect: '/finance/balance/deposit-account',
      children: [
        {
          path: '/finance/balance/deposit-account',
          meta: { title: '预存账户' },
          component: () =>
            import('../pages/finance/balance/deposit-account/deposit-account-index.vue'),
        },
        {
          path: '/finance/balance/deposit-account-flow',
          meta: { title: '预存账户记录' },
          component: () =>
            import(
              '../pages/finance/balance/deposit-account-record/deposit-account-record-index.vue'
            ),
        },
        {
          path: '/finance/balance/payment-flow',
          meta: { title: '支付记录' },
          component: () =>
            import('../pages/finance/balance/payment-history/payment-history-index.vue'),
        },
        {
          path: '/finance/balance/bank-flow',
          meta: { title: '银行流水记录' },
          component: () => import('../pages/finance/balance/bank-flow/bank-flow-index.vue'),
        },
        {
          path: '/finance/balance/refund-record',
          meta: { title: '退款记录' },
          component: () => import('../pages/finance/balance/refund-record/refund-record-index.vue'),
        },
        {
          path: '/finance/balance/recharge-record',
          meta: { title: '充值记录' },
          component: () =>
            import('../pages/finance/balance/recharge-record/recharge-record-index.vue'),
        },
        {
          path: '/finance/customer-balance/deposit-account',
          meta: { title: '预存账户' },
          component: () =>
            import('../pages/finance/customer-balance/deposit-account/deposit-account-index.vue'),
        },
        {
          path: '/finance/customer-balance/deposit-account-flow',
          meta: { title: '预存账户记录' },
          component: () =>
            import(
              '../pages/finance/customer-balance/deposit-account-record/deposit-account-record-index.vue'
            ),
        },
        {
          path: '/finance/customer-balance/payment-flow',
          meta: { title: '支付记录' },
          component: () =>
            import('../pages/finance/customer-balance/payment-history/payment-history-index.vue'),
        },

        {
          path: '/finance/customer-balance/refund-record',
          meta: { title: '退款记录' },
          component: () =>
            import('../pages/finance/customer-balance/refund-record/refund-record-index.vue'),
        },
      ],
    },
    {
      path: '/build-share',
      component: LayoutComponent,
      redirect: '/build-share/share/platform/eb-level-set',
      children: [
        {
          path: '/build-share/share/platform/eb-level-set',
          meta: { title: 'EB等级设置' },
          component: () => import('../pages/build-share/share/platform/level-set/eb-level-set.vue'),
        },
        {
          path: '/build-share/share/platform/dfm-level-set',
          meta: { title: 'DFM等级设置' },
          component: () =>
            import('../pages/build-share/share/platform/level-set/dfm-level-set.vue'),
        },
        {
          path: '/build-share/share/platform/order',
          meta: { title: '模型订单列表' },
          component: () => import('../pages/build-share/share/platform/order/order-index.vue'),
        },
        {
          path: '/build-share/share/organization/order',
          meta: { title: '订单列表' },
          component: () => import('../pages/build-share/share/organization/order/order-index.vue'),
        },
        {
          path: '/build-share/share/platform/modal-order',
          meta: { title: '模型订单明细' },
          component: () =>
            import('../pages/build-share/share/platform/model-order/modal-order.vue'),
        },
        {
          path: '/build-share/share/organization/modal-order',
          meta: { title: '模型订单明细' },
          component: () =>
            import('../pages/build-share/share/organization/model-order/modal-order.vue'),
        },
        {
          path: '/build-share/share/organization/shop-cart',
          meta: { title: '愿望清单' },
          component: () =>
            import('../pages/build-share/share/organization/shop-cart/shop-cart.vue'),
        },
        {
          path: '/build-share/share/organization/order-settlement',
          meta: { title: '订单结算' },
          component: () =>
            import('../pages/build-share/share/organization/order-settlement/order-settlement.vue'),
        },
        {
          path: '/build-share/share/platform/fire-coin-set',
          meta: { title: '火币账户' },
          component: () =>
            import('../pages/build-share/share/platform/fire-coin-set/fire-coin-set.vue'),
        },
        {
          path: '/build-share/share/organization/fire-coin-account',
          meta: { title: '火币账户' },
          component: () =>
            import(
              '../pages/build-share/share/organization/fire-coin-account/fire-coin-account.vue'
            ),
        },
        {
          path: '/build-share/share/platform/fire-coin-flow',
          meta: { title: '火币流水记录' },
          component: () =>
            import('../pages/build-share/share/platform/fire-coin-flow/fire-coin-flow.vue'),
        },
        {
          path: '/build-share/share/organization/fire-coin-flow',
          meta: { title: '火币流水记录' },
          component: () =>
            import('../pages/build-share/share/organization/fire-coin-flow/fire-coin-flow.vue'),
        },
        {
          path: '/build-share/share/platform/recharge-record',
          meta: { title: '火币充值记录' },
          component: () =>
            import('../pages/build-share/share/platform/recharge-record/recharge-record-index.vue'),
        },
        {
          path: '/build-share/share/organization/recharge-record',
          meta: { title: '火币充值记录' },
          component: () =>
            import(
              '../pages/build-share/share/organization/recharge-record/recharge-record-index.vue'
            ),
        },
        {
          path: '/build-share/share/organization/public-list',
          meta: { title: '公库记录' },
          component: () =>
            import('../pages/build-share/share/organization/public-list/public-list-index.vue'),
        },
        {
          path: '/build-share/share/organization/public-category',
          meta: { title: '公库品类' },
          component: () =>
            import(
              '../pages/build-share/share/organization/public-category/public-category-index.vue'
            ),
        },
        {
          path: '/build-share/share/organization/public-brand',
          meta: { title: '公库品牌' },
          component: () =>
            import('../pages/build-share/share/organization/public-brand/public-brand-index.vue'),
        },
        {
          path: '/build-share/share/organization/public-series',
          meta: { title: '公库系列' },
          component: () =>
            import('../pages/build-share/share/organization/public-series/public-series-index.vue'),
        },
        {
          path: '/design/export-wire-list',
          meta: { title: '导出下线表' },
          component: () => import('../pages/design/export-wire-list/export-wire-list.vue'),
        },
      ],
    },

    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../pages/error-404.vue'),
    },
  ],
})

export default router
