import { ref } from 'vue'
import { defineStore } from 'pinia'

// 用户进入组织后的信息
export const useStaff = defineStore('staff', () => {
  let defaultStaff: any
  if (localStorage.getItem('staffInfo')) {
    defaultStaff = JSON.parse(localStorage.getItem('staffInfo') || '{}')
  }

  const staffData = ref(defaultStaff)
  function setStaff(data: any) {
    staffData.value = data
    localStorage.setItem('staffInfo', JSON.stringify(data))
  }

  return { staffData, setStaff }
})
