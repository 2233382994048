<template>
  <div v-for="item in itemData" :key="item.id">
    <div v-if="item.menuName === 'line'" class="line"></div>
    <div v-else>
      <div v-if="item.children.length < 1" class="normal select-tip">
        <a
          v-if="item.url"
          :class="item.selected ? 'active' : ''"
          style="padding-left: 16px"
          @click="handleSecondNavClick(item)"
        >
          {{ item.menuName }}
          <em class="aside-tips" v-show="item.asideTips">
            {{ item.asideTipsNum }}
          </em>
        </a>
        <span v-else style="padding-left: 16px">{{ item.menuName }}</span>
      </div>
      <div v-else class="normal select-tip">
        <div>
          <i :class="!item.show ? 'iconfont open-icon' : 'iconfont close-icon'"> &#xe619; </i>
          <span @click="updateStatus(item)">{{ item.menuName }}</span>
          <ul v-show="!item.show">
            <li v-for="childrenItem in item.children" :key="childrenItem.id">
              <a
                :class="childrenItem.selected ? 'active' : ''"
                @click="handleSecondNavClick(childrenItem)"
              >
                <span>{{ childrenItem.menuName }}</span>
                <em class="aside-tips" v-show="childrenItem.asideTips">
                  {{ childrenItem.asideTipsNum }}
                </em>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, toRefs, watchEffect, ref, onUnmounted } from 'vue'
import type { PropType } from 'vue'
import { useNavTab } from '@/layout/store'
import type { MenuAsideNavData } from '../../types/menuAsideType'
import { navHrefTransfer } from '../../utils'

const secondNavProps = {
  itemData: {
    type: Array as PropType<MenuAsideNavData[]>,
    default: [],
  },
  cacheMenuInfo: {
    type: Array as PropType<string[]>,
    default: [],
  },
  cacheMenuInfoCount: {
    type: Array as PropType<number[]>,
    default: [],
  },
}

export default defineComponent({
  name: 'SecondNavItem',
  props: secondNavProps,
  setup(props, context) {
    const { itemData, cacheMenuInfo, cacheMenuInfoCount } = toRefs(props)

    const { pushTab } = useNavTab()

    // 二级导航栏下拉菜单状态
    const updateStatus = (data: MenuAsideNavData) => {
      data.show = !data.show
    }

    // 处理asideTip
    const handleAsideTip = (data: MenuAsideNavData) => {
      const cacheIndex = cacheMenuInfo.value.indexOf(data.url)

      if (cacheIndex !== -1 && cacheMenuInfoCount.value[cacheIndex] !== 0) {
        data.asideTips = true
        data.asideTipsNum =
          cacheMenuInfoCount.value[cacheIndex] > 99
            ? '99+'
            : cacheMenuInfoCount.value[cacheIndex].toString()
      }
    }

    // 递归处理数据
    const recursionHandleData = (data: MenuAsideNavData[]) => {
      if (!data) {
        return
      }

      data.forEach(item => {
        handleAsideTip(item)
        if (item.children) {
          recursionHandleData(item.children)
        }
      })
    }

    const isCtrl = ref(false)

    const isWindow = ref(false)

    function OSnow() {
      const agent = navigator.userAgent.toLowerCase()
      const isMac = /macintosh|mac os x/i.test(navigator.userAgent)
      if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
        isWindow.value = true
      }
      if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
        isWindow.value = true
      }
      if (isMac) {
        isWindow.value = false
      }
    }

    const keydown = (e: KeyboardEvent) => {
      if (e.key === 'Control' || (e.metaKey && !isWindow.value)) {
        isCtrl.value = true
      } else {
        isCtrl.value = false
      }
    }

    const keyup = () => {
      isCtrl.value = false
    }

    onMounted(() => {
      watchEffect(() => {
        recursionHandleData(itemData.value)
      })
      // watchKeyEvent()
      OSnow()
      window.addEventListener('keydown', keydown)
      window.addEventListener('keyup', keyup)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', keydown)
      window.removeEventListener('keyup', keyup)
    })

    const handleSecondNavClick = (childrenItem: MenuAsideNavData) => {
      if (isCtrl.value) {
        window.open(childrenItem.url)
      } else {
        pushTab({ path: navHrefTransfer(childrenItem.url), name: childrenItem.menuName })
        context.emit('secondNavClick')
      }
    }

    return {
      itemData,
      updateStatus,
      navHrefTransfer,
      handleSecondNavClick,
    }
  },
})
</script>
