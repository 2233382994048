<template>
  <div class="main-box">
    <nav-tab />
    <main class="main">
      <div class="main-inner">
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavTab from './nav-tab/nav-tab.vue'

export default defineComponent({
  name: 'MainIndex',
  components: {
    NavTab,
  },
})
</script>
