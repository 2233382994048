<template>
  <PageHeader :title="currentName" v-bind="{ ...$attrs }"></PageHeader>
</template>

<script setup lang="ts">
import { PageHeader } from '@skycore/skycore-ui'
import { useNavTab } from '@/layout/store'
import { storeToRefs } from 'pinia'

const { currentName } = storeToRefs(useNavTab())
</script>
<style scoped lang="less">
:deep(.sc-title) {
  .sc-title-title {
    font-size: 20px !important;
    color: #333333;
  }
}
</style>
