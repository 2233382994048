import http from './utils/http'

export const syncUserPrefer = (frontEndKey: string, preferenceJson: string) => {
  return http<UserPreference>({
    url: '/common/api/user-preference/create-or-update-user-preference',
    data: {
      frontEndKey,
      preferenceJson,
    },
    loading: false,
  })
}

export const queryUserPrefer = (frontEndKey: string) => {
  return http<UserPreference>({
    url: '/common/api/user-preference/query-user-preference',
    data: {
      frontEndKey,
    },
    loading: false,
  })
}
