import http from '@/utils/http'
import type { PaginationQuery, PaginationResData } from '../../../../types/common'
import type {
  Department,
  DepartmentResponse,
  EmployeePageQuery,
  EmployeeSeatAuthorizeCommand,
  EmployeeUserVO,
  QueryUserEmployee,
  roleListQuery,
  Staff,
  StaffDepartment,
  StaffRole,
  TabData,
} from './staff'

export interface CreateDepartmentFormData {
  parentId: number | undefined
  departmentName: string
  description: string
}

export interface UpdateDepartmentFormData {
  id: number
  parentId: number | undefined
  departmentName: string
  description: string
}

export interface CreateStaffFormData {
  employeeName: string
  gender: string
  phone: string
}

export interface UpdateStaffFormData {
  userId: number
  employeeName?: string
  kingdeeCode?: string
  organizationId?: number
  employeeEBAuthorize: boolean
  employeeDFMAuthorize: boolean
}

export interface UserDepartmentForm {
  departmentId: number
  postName: string
}

export interface UpdateStaffDepartmentListFormData {
  userId: number
  employeeDepartmentList: UserDepartmentForm[]
}

export interface UserRoleForm {
  roleId: number
  delegate: boolean
}

export interface UpdateStaffRoleListFormData {
  userId: number
  employeeRoleList: UserRoleForm[]
}

export const getDepartmentTreeData = (data?: { departmentName: string }) => {
  return http<DepartmentResponse>({
    url: '/ram/api/department/list-department-tree',
    data: data || {},
  })
}

export const createDepartment = (data: CreateDepartmentFormData) => {
  return http<Department>({ url: '/ram/api/department/create-department', data })
}

export const updateDepartment = (data: UpdateDepartmentFormData) => {
  return http<Department>({ url: '/ram/api/department/update-department', data })
}

export const deleteDepartment = (data: { id: number }) => {
  return http({ url: '/ram/api/department/delete-department', data })
}

export const updateDepartmentOrder = (data: {
  id: number
  parentId: number | undefined
  sequence: number
}) => {
  return http({ url: '/ram/api/department/update-department-sequence', data })
}

export const getStaffDepartmentList = (data: { userId: number }) => {
  return http<StaffDepartment[]>({ url: '/ram/api/department/list-employee-department', data })
}

export const updateStaffDepartmentList = (data: UpdateStaffDepartmentListFormData) => {
  return http({ url: '/ram/api/department/update-employee-department', data })
}

export const updateStaffRoleList = (data: UpdateStaffRoleListFormData) => {
  return http({ url: '/ram/api/role/update-employee-role', data })
}

export const getStaff = (data: { userId: number }) => {
  return http<EmployeeUserVO>({ url: '/ram/api/employee/query-employee', data })
}

export const getStaffList = (data: EmployeePageQuery) => {
  return http<PaginationResData<EmployeePageQuery[]>>({
    url: '/ram/api/employee/list-employee-by-page',
    data,
  })
}

export const createStaff = (data: CreateStaffFormData) => {
  return http<EmployeeUserVO>({ url: '/ram/api/employee/create-employee', data })
}

export const updateStaff = (data: UpdateStaffFormData) => {
  return http<EmployeeUserVO>({ url: '/ram/api/employee/update-employee', data })
}

export const approveStaff = (data: { userId: number }) => {
  return http({ url: '/ram/api/employee/approve-employee', data })
}

export const rejectStaff = (data: { userId: number }) => {
  return http({ url: '/ram/api/employee/reject-employee', data })
}

export const deleteStaff = (data: { id: number }) => {
  return http({ url: '/ram/api/employee/delete-employee', data })
}

export const freezeStaff = (data: { userId: number }) => {
  return http({ url: '/ram/api/employee/freeze-employee', data })
}

export const unfreezeStaff = (data: { userId: number }) => {
  return http({ url: '/ram/api/employee/unfreeze-employee', data })
}

export const updateStaffPassword = (data: { userId: number; password: string }) => {
  return http({ url: '/ram/api/employee/update-password', data })
}

export const getRoleList = () => {
  return http<PaginationResData<{ id: number; roleName: string }[]>>({
    url: '/ram/api/role/list-role-by-page',
    data: {},
  })
}

export const getBriefRoleList = (data?: roleListQuery) => {
  return http<{ id: number; name: string }[]>({
    url: '/ram/api/role/list-role-brief',
    data,
  })
}

export const getStaffRoleList = (data: { userId: number }) => {
  return http<StaffRole[]>({
    url: '/ram/api/role/list-employee-role',
    data,
  })
}

export const queryListTab = (data?: EmployeePageQuery) => {
  return http<TabData>({
    url: '/ram/api/employee/count-bubble',
    data,
  })
}

export const queryUserEmployeeInfo = () => {
  return http<QueryUserEmployee>({
    url: '/ram/api/user/query-user-employee-info',
  })
}

export const batchEmployeeAuthorize = (data: EmployeeSeatAuthorizeCommand) => {
  return http({
    url: '/ram/api/employee/batch-employee-authorize-seats',
    data,
  })
}

export const getOrganizationAuthorizeSeatsTitle = (authorizeType: string) => {
  return http({
    url: `/ram/api/employee/get-organization-authorize-seats-title?authorizeType=${authorizeType}`,
  })
}
