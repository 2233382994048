<template>
  <div class="fixed inset-0 flex">
    <div class="p-3 w-[60px] h-full bg-[#f0f0f0]">
      <a-skeleton-avatar class="mb-4 rounded-lg overflow-hidden" />
      <a-skeleton-avatar class="mb-4 rounded-lg overflow-hidden" />
      <a-skeleton-avatar class="mb-4 rounded-lg overflow-hidden" />
    </div>
    <div class="px-4 py-2 w-[130px] h-full bg-[#f9f9f9]">
      <a-skeleton />
      <a-skeleton />
    </div>
    <div class="h-full flex-grow">
      <div class="p-3 w-full h-[40px] bg-[#f9f9f9]">
        <a-skeleton-button size="small" class="mr-6 bg-white" />
        <a-skeleton-button size="small" class="mr-6 bg-white" />
      </div>
      <div class="p-4">
        <a-skeleton-button size="small" />
        <a-skeleton />
      </div>
    </div>
  </div>
</template>
